import React from "react";
import CustomerRequirementsFiber from "components/new-design/CustomerRequirements/CustomerRequirementsFiber";
import { InfoModalVariants } from "components/new-design/CustomerRequirements/CustomerRequirementsInfoModal";
import CustomerRequirementsWrapper from "components/new-design/CustomerRequirements/CustomerRequirementsWrapper";
import CustomerRequirementsHeader from "components/new-design/CustomerRequirements/CustomerRequirementsHeader";
import { observer } from "mobx-react";
import { useStores } from "stores/BusinessClient";
import CustomerRequirementsInternationalTariff from "components/new-design/CustomerRequirements/CustomerRequirementsInternationalTariff";
import CustomerRequirementsDesktop from "components/new-design/CustomerRequirements/CustomerRequirementsDesktop";
import CustomerRequirementsMobile from "components/new-design/CustomerRequirements/CustomerRequirementsMobile";
import CustomerRequirementsHeaderExtraElements from "./CustomerRequirementsHeaderExtraElements";
import CustomerRequirementsUseCases from "./CustomerRequirementsUseCases";
import CustomerRequirementsSmartphone from "./CustomerRequirementsSmartphone";
import CustomerRequirementsBusinessSize from "./CustomerRequirementsBusinessSize";
import CustomerRequirementsSpecialistQuestions from "./CustomerRequirementsSpecialistQuestions";

type CustomerRequirementsBCProps = {
  openInfoModal: (variant: InfoModalVariants) => void;
  trackingContext: string;
};

const CustomerRequirementsBC = ({
  openInfoModal,
  trackingContext,
}: CustomerRequirementsBCProps) => {
  const { customerRequirementsStore } = useStores();

  const isBusinessClient = true;

  const useCaseComponent = (
    <CustomerRequirementsUseCases trackingContext={trackingContext} />
  );

  const fiberCableComponent = (
    <CustomerRequirementsFiber
      fiber={
        customerRequirementsStore.customerRequirementsBase.requirements.fiber
      }
      toggleFiber={(value) =>
        customerRequirementsStore.customerRequirementsBase.toggleFiber(value)
      }
      trackingContext={trackingContext}
      isBusinessClient={isBusinessClient}
    />
  );

  const internationalTariffComponent = (
    <CustomerRequirementsInternationalTariff
      trackingContext={trackingContext}
      openInfoModal={openInfoModal}
      isBusinessClient={isBusinessClient}
      customerRequirementsBase={
        customerRequirementsStore.customerRequirementsBase
      }
      recommendation={
        customerRequirementsStore.recommendations.internationalTariffs
      }
    />
  );

  const smartphoneConsultationComponent = (
    <CustomerRequirementsSmartphone trackingContext={trackingContext} />
  );

  const businessSizeComponent = (
    <CustomerRequirementsBusinessSize
      openInfoModal={openInfoModal}
      trackingContext={trackingContext}
    />
  );

  const SpecialistQuestionsComponent = (
    <CustomerRequirementsSpecialistQuestions
      openInfoModal={openInfoModal}
      trackingContext={trackingContext}
    />
  );

  const customerRequirementsProps = {
    components: {
      useCase: useCaseComponent,
      fiber: fiberCableComponent,
      internationalTariff: internationalTariffComponent,
      smartphone: smartphoneConsultationComponent,
      customerSize: businessSizeComponent,
      magentaShape: SpecialistQuestionsComponent,
    },
    customerRequirementsBase:
      customerRequirementsStore.customerRequirementsBase,
    isBusinessClient,
    openInfoModal,
    trackingContext,
  };

  return (
    <CustomerRequirementsWrapper
      header={
        <CustomerRequirementsHeader>
          <CustomerRequirementsHeaderExtraElements
            trackingContext={trackingContext}
            openInfoModal={openInfoModal}
          />
        </CustomerRequirementsHeader>
      }
    >
      <CustomerRequirementsDesktop {...customerRequirementsProps} />
      <CustomerRequirementsMobile {...customerRequirementsProps} />
    </CustomerRequirementsWrapper>
  );
};

export default observer(CustomerRequirementsBC);
