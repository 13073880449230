import React, { useState } from "react";
import { CustomerType } from "core/entities/PencilSelling/Customer/ICustomer";
import CustomerRequirementsInfoModal, {
  InfoModalVariants,
} from "components/new-design/CustomerRequirements/CustomerRequirementsInfoModal";
import { trackClick } from "helpers/reactTracking";
import CustomerRequirementsFiber from "components/new-design/CustomerRequirements/CustomerRequirementsFiber";
import CustomerRequirementsWrapper from "components/new-design/CustomerRequirements/CustomerRequirementsWrapper";
import CustomerRequirementsHeader from "components/new-design/CustomerRequirements/CustomerRequirementsHeader";
import { observer } from "mobx-react";
import { useStores } from "stores/PrivateClient";
import CustomerRequirementsInternationalTariff from "components/new-design/CustomerRequirements/CustomerRequirementsInternationalTariff";
import CustomerRequirementsDesktop from "components/new-design/CustomerRequirements/CustomerRequirementsDesktop";
import CustomerRequirementsMobile from "components/new-design/CustomerRequirements/CustomerRequirementsMobile";
import CustomerRequirementsHeaderExtraElements from "./CustomerRequirementsHeaderExtraElements";
import CustomerRequirementsUseCases from "./CustomerRequirementsUseCases";
import CustomerRequirementsSmartphone from "./CustomerRequirementsSmartphone";
import CustomerRequirementsHousehold from "./CustomerRequirementsHousehold";
import CustomerRequirementsStreaming from "./CustomerRequirementsStreaming";

type CustomerRequirementsPCProps = {
  openInfoModal: (variant: InfoModalVariants) => void;
  trackingContext: string;
};

const CustomerRequirementsPC = ({
  openInfoModal,
  trackingContext,
}: CustomerRequirementsPCProps) => {
  const isBusinessClient = false;

  const { customerRequirementsStore } = useStores();

  const useCaseComponent = (
    <CustomerRequirementsUseCases trackingContext={trackingContext} />
  );

  const fiberComponent = (
    <CustomerRequirementsFiber
      fiber={
        customerRequirementsStore.customerRequirementsBase.requirements.fiber
      }
      toggleFiber={(value) => customerRequirementsStore.toggleFiber(value)}
      trackingContext={trackingContext}
      isBusinessClient={isBusinessClient}
    />
  );

  const internationalTariffComponent = (
    <CustomerRequirementsInternationalTariff
      trackingContext={trackingContext}
      openInfoModal={openInfoModal}
      isBusinessClient={isBusinessClient}
      customerRequirementsBase={
        customerRequirementsStore.customerRequirementsBase
      }
      recommendation={
        customerRequirementsStore.recommendations.internationalTariffs
      }
    />
  );

  const smartphoneConsultationComponent = (
    <CustomerRequirementsSmartphone
      trackingContext={trackingContext}
      openInfoModal={openInfoModal}
    />
  );

  const householdComponent = (
    <CustomerRequirementsHousehold
      trackingContext={trackingContext}
      openInfoModal={openInfoModal}
    />
  );

  const streamingComponent = (
    <CustomerRequirementsStreaming
      trackingContext={trackingContext}
      openInfoModal={openInfoModal}
    />
  );

  const headerComponent = (
    <CustomerRequirementsHeader>
      <CustomerRequirementsHeaderExtraElements
        trackingContext={trackingContext}
      />
    </CustomerRequirementsHeader>
  );

  return (
    <CustomerRequirementsWrapper header={headerComponent}>
      <CustomerRequirementsDesktop
        components={{
          useCase: useCaseComponent,
          fiber: fiberComponent,
          internationalTariff: internationalTariffComponent,
          smartphone: smartphoneConsultationComponent,
          magentaShape: streamingComponent,
          customerSize: householdComponent,
        }}
        customerRequirementsBase={
          customerRequirementsStore.customerRequirementsBase
        }
        isBusinessClient={isBusinessClient}
        openInfoModal={openInfoModal}
        trackingContext={trackingContext}
      />
      <CustomerRequirementsMobile
        components={{
          useCase: useCaseComponent,
          fiber: fiberComponent,
          internationalTariff: internationalTariffComponent,
          smartphone: smartphoneConsultationComponent,
          magentaShape: streamingComponent,
          customerSize: householdComponent,
        }}
        customerRequirementsBase={
          customerRequirementsStore.customerRequirementsBase
        }
        isBusinessClient={isBusinessClient}
        openInfoModal={openInfoModal}
        trackingContext={trackingContext}
      />
    </CustomerRequirementsWrapper>
  );
};

export default observer(CustomerRequirementsPC);
