import React from "react";
import { Checkbox, CopyText, Heading } from "@dtpk-cc/components";
import { ToggleBtn } from "elements/new-design/ToggleBtn";
import { useStores } from "stores/BusinessClient";
import { observer } from "mobx-react";
import DropdownMain from "elements/new-design/DropdownMain";
import {
  CallLocationVariants,
  ITSupportVariants,
} from "core/entities/PencilSelling/BusinessClient/CustomerRequirements/ICustomerRequirementsBC";
import { componentLinks } from "components/new-design/CustomerRequirements/const";
import { InfoModalVariants } from "components/new-design/CustomerRequirements/CustomerRequirementsInfoModal";
import CustomerRequirementsSectionHeader from "components/new-design/CustomerRequirements/CustomerRequirementsSectionHeader";
import { trackClick } from "helpers/reactTracking";
import * as styles from "./customer-requirements-specialist-questions.module.scss";
import SpecialistIcon from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/specialist.svg";
import InternetIcon from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/internet.svg";

const specialistQuestionsContentMap = {
  telephony: {
    dropdownValues: ["", "1-2", "3-4", "5-6", "7-8", "≥ 8"],
    callLocationItems: [
      {
        identifier: CallLocationVariants.HOMEOFFICE,
        title: "Homeoffice",
      },
      {
        identifier: CallLocationVariants.INTERNATIONAL,
        title: "Telefonie ins Ausland",
      },
      {
        identifier: CallLocationVariants.MOBILE,
        title: "Telefonie in Mobilfunknetze",
      },
    ],
  },
  network: {
    connectedDevicesDropdownValues: ["", "1-2", "3-4", "5-6", "7-8", "≥ 8"],
    officeAreaDropdownValues: [
      "",
      "< 50 m²",
      "50-100 m²",
      "100-200 m²",
      "≥ 200 m²",
    ],
    itSupportItems: [
      {
        identifier: ITSupportVariants.ON_SITE,
        title: "Analyse vor Ort",
      },
      {
        identifier: ITSupportVariants.INHOUSE_IT_SUPPORT,
        title: "Eigener IT Support",
      },
      {
        identifier: ITSupportVariants.CUSTOMER_WLAN,
        title: "Kunden WLAN",
      },
    ],
    internetVisibility: {
      identifier: "visibility_from_the_internet",
      title: "Sichtbarkeit im Internet",
    },
    crm: {
      identifier: "crm_system",
      title: "CRM-System",
    },
  },
};

type CustomerRequirementsSpecialistQuestionsProps = {
  openInfoModal: (variant: InfoModalVariants) => void;
  trackingContext: string;
};

const CustomerRequirementsSpecialistQuestions = ({
  openInfoModal,
  trackingContext,
}: CustomerRequirementsSpecialistQuestionsProps) => {
  const { customerRequirementsStore } = useStores();

  return (
    <div className={styles.specialistQuestionsContainer}>
      <CustomerRequirementsSectionHeader
        link={componentLinks.specialistQuestions}
        sectionTitle="Spezialisten-Fragen"
        titleCustomClass={styles.colorWhite}
        onInfoClick={() =>
          openInfoModal(InfoModalVariants.SPECIALIST_QUESTIONS)
        }
      />
      <div className={styles.specialistQuestionsContent}>
        <div className={styles.telephonySectionContainer}>
          <div className={styles.telephonyFirstRow}>
            <div className={styles.titleRowWithIcon}>
              <SpecialistIcon className={styles.icon}></SpecialistIcon>
              <Heading
                variants={[
                  Heading.Variant.quaternary,
                  Heading.Variant.uppercase,
                ]}
                customClass={styles.copyTextSpecialistQuestions}
              >
                Telefonie
              </Heading>
            </div>
            <DropdownMain
              items={specialistQuestionsContentMap.telephony.dropdownValues}
              value={
                customerRequirementsStore.requirements.specialistQuestions
                  .telephony.maxParallelCalls || ""
              }
              onSelection={(value) => {
                trackClick(
                  `specialistquestions.telephony.maxparallelcalls.${
                    value || "0"
                  }`,
                  trackingContext
                );
                customerRequirementsStore.setMaxParallelCalls(value);
              }}
              customClass={styles.specialistQuestionsDropdown}
              label="Anzahl paralleler Gespräche"
            />
          </div>
          <div className={styles.toggleBtnContainer}>
            {specialistQuestionsContentMap.telephony.callLocationItems.map(
              (item) => (
                <ToggleBtn
                  title={item.title}
                  key={item.identifier}
                  id={`telephony_call_locations_${item.identifier}`}
                  inputTestId={`telephony_call_locations_${item.identifier}`}
                  checked={customerRequirementsStore.requirements.specialistQuestions.telephony.callLocations.includes(
                    item.identifier
                  )}
                  onChange={() => {
                    trackClick(
                      `specialistquestions.telephony.calllocations.${item.identifier}`,
                      trackingContext
                    );
                    customerRequirementsStore.setCallLocations(item.identifier);
                  }}
                  customTitleClass={`${
                    customerRequirementsStore.requirements.specialistQuestions.telephony.callLocations.includes(
                      item.identifier
                    )
                      ? styles.colorMagenta
                      : styles.colorWhite
                  }`}
                  customTileClass={`${styles.toggleBtnTile}
                    ${
                      customerRequirementsStore.requirements.specialistQuestions.telephony.callLocations.includes(
                        item.identifier
                      )
                        ? styles.bgWhite
                        : styles.bgTransparent
                    }`}
                />
              )
            )}
          </div>
        </div>
        <div className={styles.networkSectionContainer}>
          <div className={styles.titleRowWithIcon}>
            <InternetIcon className={styles.icon}></InternetIcon>
            <Heading
              variants={[Heading.Variant.quaternary, Heading.Variant.uppercase]}
              customClass={styles.copyTextSpecialistQuestions}
            >
              Netzwerk
            </Heading>
          </div>
          <div className={styles.toggleBtnContainer}>
            {specialistQuestionsContentMap.network.itSupportItems.map(
              (item) => (
                <ToggleBtn
                  placeItems="start"
                  title={item.title}
                  key={item.identifier}
                  id={`telephony_call_locations_${item.identifier}`}
                  inputTestId={`telephony_call_locations_${item.identifier}`}
                  checked={customerRequirementsStore.requirements.specialistQuestions.network.itSupport.includes(
                    item.identifier
                  )}
                  onChange={() => {
                    trackClick(
                      `specialistquestions.network.itSupport.${item.identifier}`,
                      trackingContext
                    );
                    customerRequirementsStore.setITSupport(item.identifier);
                  }}
                  customTitleClass={`${
                    customerRequirementsStore.requirements.specialistQuestions.network.itSupport.includes(
                      item.identifier
                    )
                      ? styles.colorMagenta
                      : styles.colorWhite
                  }`}
                  customTileClass={`${styles.toggleBtnTile}
                ${
                  customerRequirementsStore.requirements.specialistQuestions.network.itSupport.includes(
                    item.identifier
                  )
                    ? styles.bgWhite
                    : styles.bgTransparent
                }`}
                />
              )
            )}
          </div>
          <div className={styles.networkDropdownContainer}>
            <DropdownMain
              items={
                specialistQuestionsContentMap.network
                  .connectedDevicesDropdownValues
              }
              value={
                customerRequirementsStore.requirements.specialistQuestions
                  .network.connectedDevices || ""
              }
              onSelection={(value) => {
                trackClick(
                  `specialistquestions.network.connecteddevices.${
                    value || "0"
                  }`,
                  trackingContext
                );
                customerRequirementsStore.setConnectedDevices(value);
              }}
              label="Angeschlossene Geräte"
              customClass={styles.connectedDevicesDropdownOpenTop}
            />
            <DropdownMain
              items={
                specialistQuestionsContentMap.network.officeAreaDropdownValues
              }
              value={
                customerRequirementsStore.requirements.specialistQuestions
                  .network.sizeOfOffice || ""
              }
              onSelection={(value) => {
                trackClick(
                  `specialistquestions.network.officearea.${value || "0"}`,
                  trackingContext
                );
                customerRequirementsStore.setOfficeArea(value);
              }}
              customClass={styles.officeAreaDropdownOpenTop}
              label="Größe der Büroräume"
            />
          </div>
          <div className={styles.networkDropdownContainer}>
            <Checkbox
              key={
                specialistQuestionsContentMap.network.internetVisibility
                  .identifier
              }
              id={
                specialistQuestionsContentMap.network.internetVisibility
                  .identifier
              }
              title={
                specialistQuestionsContentMap.network.internetVisibility.title
              }
              checked={
                customerRequirementsStore.requirements.specialistQuestions
                  .network.internetVisibility
              }
              onChange={() => {
                trackClick(
                  "specialistquestions.network.internetvisibility",
                  trackingContext
                );
                customerRequirementsStore.toggleInternetVisibility();
              }}
              wrapperProps={{ customClass: styles.specialistQuestionsCheckbox }}
            >
              <CopyText customClass={styles.copyTextSpecialistQuestions}>
                {specialistQuestionsContentMap.network.internetVisibility.title}
              </CopyText>
            </Checkbox>
            <Checkbox
              key={specialistQuestionsContentMap.network.crm.identifier}
              id={specialistQuestionsContentMap.network.crm.identifier}
              checked={
                customerRequirementsStore.requirements.specialistQuestions
                  .network.crm
              }
              onChange={() => {
                trackClick("specialistquestions.network.crm", trackingContext);
                customerRequirementsStore.toggleCrm();
              }}
              wrapperProps={{ customClass: styles.specialistQuestionsCheckbox }}
            >
              <CopyText customClass={styles.copyTextSpecialistQuestions}>
                {specialistQuestionsContentMap.network.crm.title}
              </CopyText>
            </Checkbox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(CustomerRequirementsSpecialistQuestions);
