import { Button, Heading } from "@dtpk-cc/components";
import ModalWrapper from "elements/new-design/ModalWrapper";
import React from "react";
import * as styles from "./customer-requirements-info-modal.module.scss";

export interface InfoModalContentsProps {
  title: string;
  content: string | React.ReactNode;
}

export enum InfoModalVariants {
  BANDWIDTH = "bandwidth",
  BANDWIDTH_GK = "bandwidth-gk",
  STREAMING = "streaming",
  HOUSEHOLD = "household",
  DEVICES = "devices",
  DEVICES_GK = "devices-gk",
  INTERNATIONAL_TARIFF = "international-tariff",
  INTERNATIONAL_TARIFF_GK = "international-tariff-gk",
  SMARTPHONE_DESIRED = "smartphone-desired",
  SPECIALIST_QUESTIONS = "specialist-questions",
  BUSINESS_SIZE = "business-size",
  BRANCHE = "branche",
  DEFAULT = "default",
}

const InfoModalContentMap = {
  [InfoModalVariants.BANDWIDTH]: {
    title: "Bandbreite",
    content:
      "Wenn eine höhere Geschwindigkeit als bisher verfügbar ist, empfehlen wir eine Erhöhung der Geschwindigkeit. Wenn Homeoffice, Videotelefonie oder Gaming ausgewählt wurden, sollte eine hohe Geschwindigkeit empfohlen werden. Bei Glasfaserverfügbarkeit ist die Empfehlung ein Glasfaser-Tarif.",
  },
  [InfoModalVariants.BANDWIDTH_GK]: {
    title: "Bandbreite",
    content:
      "Wenn eine höhere Geschwindigkeit als bisher verfügbar ist, empfehlen wir eine Erhöhung der Geschwindigkeit. Für Unternehmen mit intensivem Videokonferenz-Bedarf, Cloud-Anwendungen oder datenintensiven Prozessen mit hoher Geschwindigkeit. Bei Glasfaserverfügbarkeit empfehlen wir einen Business Glasfaser-Tarif für maximale Leistung und Zuverlässigkeit.",
  },
  [InfoModalVariants.STREAMING]: {
    title: "Streaming",
    content:
      "Bei Nutzung einer der Streaming Dienste Netflix, Disney+ und AppleTV+, ist die Empfehlung MegaStream. Bei Nutzung einer der Sport Streaming Dienste MagentaSport, WOW und DAZN, ist die Empfehlung MegaSport.",
  },
  [InfoModalVariants.HOUSEHOLD]: {
    title: "Haushalt",
    content:
      "Ab 2 Personen oder einem Kind ist die Empfehlung eine PlusKarte. Je mehr Personen in einem Haushalt leben, desto höher ist die benötigte Internetgeschwindigkeit.",
  },
  [InfoModalVariants.INTERNATIONAL_TARIFF]: {
    title: "Mobilfunk Ausland",
    content:
      "In den MagentaMobil Tarifen L und XL ist eine Telefonie-Flat in die Länder der EU, CH, UK, Türkei enthalten.",
  },
  [InfoModalVariants.INTERNATIONAL_TARIFF_GK]: {
    title: "Mobilfunk Ausland",
    content:
      "Bei Geschäftsreisen oder Telefonie ins Ausland empfehlen wir den Business Mobil Tarif XL: Telefonie-Flat in die Länder der EU, CH, UK, Türkei und USA/Kanada enthalten.",
  },
  [InfoModalVariants.SMARTPHONE_DESIRED]: {
    title: "Smartphone Beratung",
    content:
      "Zu jedem neuen Smartphone (mit Smartphone Tarif) empfehlen wir eine Handyversicherung.",
  },
  [InfoModalVariants.DEVICES]: {
    title: "Geräte",
    content:
      "Je mehr Geräte in einem Haushalt über WLAN angeschlossen sind, desto höher ist die benötigte Internetgeschwindigkeit.",
  },
  [InfoModalVariants.DEVICES_GK]: {
    title: "Geräte",
    content:
      "Konnektivität einzelner Geräte oder auch Fahrzeuge wird immer wichtiger. Bei der Nutzung mehrerer Geräte empfehlen wir Tarife mit inkludierter MultiSIM.",
  },
  [InfoModalVariants.SPECIALIST_QUESTIONS]: {
    title: "Spezialistenfragen",
    content:
      "Wenn der Kunde Interesse an diesen Themen hat, leite ihn gerne an den dir bekannten GK Spezialisten weiter.",
  },
  [InfoModalVariants.BUSINESS_SIZE]: {
    title: "Unternehmen",
    content: "Bei mehreren Mitarbeitern empfehlen wir die Business Cards.",
  },
  [InfoModalVariants.BRANCHE]: {
    title: "Branche",
    content: (
      <ul>
        <li>
          <b>Handwerk</b>: Dazu gehören Tischler, Elektriker, Klempner, Maler
          und andere Handwerkliche Berufe.
        </li>
        <li>
          <b>Einzelhandel</b>: Lokale Geschäfte, die Waren wie Kleidung,
          Lebensmittel, Elektronik und andere Konsumgüter verkaufen.
        </li>
        <li>
          <b>Gastronomie</b>: Restaurants, Cafés, Bars und Catering-Dienste.
        </li>
        <li>
          <b>Dienstleistungen</b>: Dazu zählen beispielsweise Friseure,
          Reinigungsdienste, Beratungsfirmen und Marketingagenturen.
        </li>
        <li>
          <b>Produktion und Fertigung</b>: Kleinere Hersteller von Produkten,
          wie Lebensmitteln bis hin zu Maschinenbauteilen.
        </li>
        <li>
          <b>Bauwesen</b>: Bauunternehmen, Wohn- und Gewerbeimmobilien
          Transportdienste, Lagerhaltung, Logistiklösungen.
        </li>
        <li>
          <b>IT und Technologie</b>: IT-Dienstleister,
          Softwareentwicklungsfirmen und Technikberatung
        </li>
        <li>
          <b>Gesundheitswesen</b>: Arztpraxen, Physiotherapeuten, Apotheken und
          ähnliche Einrichtungen.
        </li>
        <li>
          <b>Kreativwirtschaft</b>: Designstudios, Werbeagenturen,
          Fotografie-Dienstleistungen und andere kreative Dienstleistungen.
        </li>
      </ul>
    ),
  },
  [InfoModalVariants.DEFAULT]: {
    title: "",
    content: "",
  },
};

type CustomerRequirementsInfoModalProps = {
  onClose: () => void;
  isOpen: boolean;
  variant: InfoModalVariants;
};

const CustomerRequirementsInfoModal = ({
  onClose,
  isOpen,
  variant,
}: CustomerRequirementsInfoModalProps) => {
  const { title, content } = InfoModalContentMap[variant];
  return (
    <ModalWrapper
      containerCustomClass={styles.infoModal}
      isOpen={isOpen}
      onClose={onClose}
      onBackDropClick={onClose}
    >
      <Heading
        tag="h3"
        variants={[Heading.Variant.secondary, Heading.Variant.display]}
        customClass={styles.modalTitle}
      >
        {title}
      </Heading>
      <Heading tag="h3" variants={[Heading.Variant.quaternary]}>
        {content}
      </Heading>
      <div className={styles.modalFooter}>
        <Button customClass={styles.modalCloseButton} onClick={onClose}>
          Verstanden
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default CustomerRequirementsInfoModal;
