import React, { useState } from "react";
import { observer } from "mobx-react";
import StepHeader from "components/new-design/StepHeader";
import { CustomerRequirementsPC } from "core/entities/PencilSelling/PrivateClient/CustomerRequirements/CustomerRequirementsPC";
import { CustomerRequirementsBC } from "core/entities/PencilSelling/BusinessClient/CustomerRequirements/CustomerRequirementsBC";
import PageWrapper from "../../../elements/new-design/PageWrapper";
import PageInner from "../../../elements/new-design/PageWrapper/PageInner";
import { ICartConfigurationType } from "../../../core/entities/PencilSelling/Configurations/IConfigurations";
import SummaryMobile from "./SummaryMobile";
import SummaryLandline from "./SummaryLandline";
import SummaryDevices from "./SummaryDevices";
import SummaryTotalPrice from "./SummaryTotalPrice";
import { useQrCodeModalData } from "../../../hooks/useQrCodeModalData";
import SummaryWrapper from "./SummaryWrapper";
import SummaryDetails from "./SummaryDetails";
import QRCodeModal from "./QRCodeModal";
import SummaryCards from "./SummaryCards";
import { trackClick, trackPdf } from "../../../helpers/reactTracking";
import { QRCodeResponse } from "../../../core/repositories/ProductsRepository/ResponseInterface";
import SummaryPDFButtons from "./SummaryPDFButtons";
import { CustomerType } from "../../../core/entities/PencilSelling/Customer/ICustomer";
import { BaseOffer } from "../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { ProductsRepository } from "../../../core/repositories/ProductsRepository/ProductsRepository";
import { IGetSummaryData } from "../../../hooks/PencilSelling/useGetSummaryData";
import { mapTotalPricesForPDF } from "./helpers";
import { getOfferLinkEmailText } from "./const";
import OfferLinkModal from "./OfferLinkModal";
import {
  ToasterTypes,
  useToaster,
} from "../../../hooks/PencilSelling/useToast";
import SummaryMobileAveragePriceData from "./SummaryMobileAveragePriceData";
import { IOfferConfigurationsSummary } from "./ISummary";

type SummaryProps = {
  offerStore: BaseOffer;
  productsRepositoryStore: ProductsRepository;
  getSummaryData: IGetSummaryData;
  customerRequirementsStore: CustomerRequirementsPC | CustomerRequirementsBC;
};

const Summary = ({
  getSummaryData,
  offerStore,
  productsRepositoryStore,
  customerRequirementsStore,
}: SummaryProps) => {
  const { setIsLoading } = offerStore;
  const { qrCodeModalData, setQrCodeModalData } = useQrCodeModalData();
  const toasterMap = useToaster();
  const { customerType, customerPricePrefix, domainType } =
    offerStore.customer.get();
  const customerTypeMap = {
    [CustomerType.BUSINESS]: "business",
    [CustomerType.EMPLOYEE]: "bge",
    [CustomerType.PRIVATE]: "private",
  };
  const [offerLink, setOfferLink] = useState("");
  const [pdfOfferDoc, setPdfOfferDoc] = useState("");
  const [offerLinkModalIsOpen, setOfferLinkModalIsOpen] = useState(false);

  const {
    configurations,
    configurationsExists,
    offerPresenterExport,
    savingsTotalValue,
    customerData,
  } = getSummaryData();

  const displayConfigurationTotalPrice =
    "displayConfigurationTotalPrice" in offerPresenterExport
      ? offerPresenterExport.displayConfigurationTotalPrice
      : true;
  const displayOfferTotalPrice =
    "displayOfferTotalPrice" in offerPresenterExport
      ? offerPresenterExport.displayOfferTotalPrice
      : true;

  const pdfPayload: IOfferConfigurationsSummary = {
    offer_data: {
      customerData,
      details: offerPresenterExport.details,
      distributionData: offerPresenterExport.distributionData,
      mobileAveragePriceData: offerPresenterExport.mobileAveragePriceData,
      bannerLinks: offerPresenterExport.bannerLinks,
      configurations: mapTotalPricesForPDF(
        configurations,
        displayConfigurationTotalPrice,
        displayOfferTotalPrice
      ),
    },
    customer_requirements: customerRequirementsStore.export(false),
    tool_domain: domainType,
    bge_context: customerType === CustomerType.EMPLOYEE,
  };

  const getOfferPdf = async () => {
    try {
      let pdfResponse = pdfOfferDoc;
      if (!pdfOfferDoc) {
        setIsLoading(true);
        pdfResponse = await productsRepositoryStore.submitOrder(pdfPayload);
      }
      setPdfOfferDoc(pdfResponse);
      trackPdf(
        `content.button.pdf-drucken ${customerTypeMap[customerType]}`,
        pdfPayload
      );
      if (pdfResponse)
        window.open(pdfResponse, "_blank", "noopener,noreferrer");
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      console.log(e?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getPdfQRCode = async () => {
    setIsLoading(true);
    try {
      const qrCodeRes = await productsRepositoryStore.submitPdfQrCode(
        pdfPayload
      );
      const parsedQrCodeData = (await qrCodeRes.json()) as QRCodeResponse;

      setQrCodeModalData({
        isOpen: true,
        urlLink: parsedQrCodeData.url,
        imageSrc: `data:image/jpg;base64,${parsedQrCodeData.qr_code}`,
      });
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      console.log("error", e?.message);
    } finally {
      setIsLoading(false);
    }
    trackClick(`${customerTypeMap[customerType]} PDF-QR-Code`);
  };

  const getOfferPdfLink = async () => {
    try {
      let offerLinkValue = offerLink;
      if (!offerLinkValue) {
        setIsLoading(true);
        offerLinkValue = await productsRepositoryStore.getOfferPdfLink(
          pdfPayload
        );
      }
      setOfferLink(offerLinkValue);
      await navigator.clipboard.writeText(
        getOfferLinkEmailText(offerLinkValue)
      );
      toasterMap[ToasterTypes.SUCCESS]("In die Zwischenablage kopiert");
    } catch (error) {
      if ("name" in error && error.name === "NotAllowedError") {
        setOfferLinkModalIsOpen(true);
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      console.log("error", error?.message);
    } finally {
      setIsLoading(false);
    }
    trackClick(`${customerTypeMap[customerType]}.pdf.link`);
  };

  const offerLinkModalSubmitHandler = async () => {
    try {
      await navigator.clipboard.writeText(getOfferLinkEmailText(offerLink));
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      console.log("error", e?.message);
    }
    setOfferLinkModalIsOpen(false);
  };

  return (
    <PageWrapper>
      <StepHeader
        title="Ihre persönliche Empfehlung"
        subtitle="Vielen Dank für Ihr Interesse!"
      >
        <SummaryPDFButtons
          getOfferPdf={getOfferPdf}
          getPdfQRCode={getPdfQRCode}
          getOfferPdfLink={getOfferPdfLink}
        />
      </StepHeader>
      <PageInner>
        {configurationsExists && (
          <SummaryWrapper>
            <div>
              <SummaryMobile
                customerPricePrefix={customerPricePrefix}
                displayConfigurationTotalPrice={displayConfigurationTotalPrice}
                configurations={configurations[ICartConfigurationType.MOBILE]}
              />
              <SummaryCards
                customerPricePrefix={customerPricePrefix}
                displayConfigurationTotalPrice={displayConfigurationTotalPrice}
                configurations={configurations[ICartConfigurationType.CARD]}
              />
              <SummaryLandline
                customerPricePrefix={customerPricePrefix}
                displayConfigurationTotalPrice={displayConfigurationTotalPrice}
                configurations={configurations[ICartConfigurationType.LANDLINE]}
              />
              <SummaryDevices
                customerPricePrefix={customerPricePrefix}
                displayConfigurationTotalPrice={displayConfigurationTotalPrice}
                configurations={configurations[ICartConfigurationType.DEVICE]}
              />
            </div>
            {displayOfferTotalPrice && (
              <SummaryTotalPrice
                configurationsTotalPrice={configurations.totalPrice}
                savingsTotalValue={savingsTotalValue}
              />
            )}
          </SummaryWrapper>
        )}
        {offerPresenterExport.mobileAveragePriceData && (
          <SummaryMobileAveragePriceData
            customerPricePrefix={customerPricePrefix}
            mobileAveragePriceData={offerPresenterExport.mobileAveragePriceData}
          />
        )}
        <SummaryDetails details={offerPresenterExport.details} />
      </PageInner>
      <QRCodeModal
        onClose={() =>
          setQrCodeModalData((prev) => ({ ...prev, isOpen: !prev.isOpen }))
        }
        qrCodeData={qrCodeModalData}
      />
      {offerLinkModalIsOpen && (
        <OfferLinkModal
          isOpen={offerLinkModalIsOpen}
          onCancel={() => setOfferLinkModalIsOpen(false)}
          onSubmit={offerLinkModalSubmitHandler}
          offerLink={offerLink}
        />
      )}
    </PageWrapper>
  );
};

export default observer(Summary);
