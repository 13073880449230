import React from "react";
import { observer } from "mobx-react";
import OfferFinalizationLeadKek from "components/new-design/OfferFinalization/OfferFinalizationLeadKek";
import { useStores } from "../../../../stores/PrivateClient";
import TotalPriceToggle from "../../../../components/new-design/OfferFinalization/TotalPriceDisplayToggle";
import OfferFinalizationWrapper from "../../../../components/new-design/OfferFinalization/OfferFinalizationWrapper";
import OfferFinalizationDateTime from "../../../../components/new-design/OfferFinalization/OfferFinalizationDateTime";
import OfferFinalizationShopData from "../../../../components/new-design/OfferFinalization/OfferFinalizationShopData";
import { IDistributionData } from "../../../../core/presenter/PencilSelling/OfferSummaryBasePresenter";
import * as styles from "./offer-finalization.module.scss";

interface OfferFinalizationProps {
  distributionData: IDistributionData;
}

const OfferFinalization = ({ distributionData }: OfferFinalizationProps) => {
  const { offerStore } = useStores();

  return (
    <OfferFinalizationWrapper
      customClass={styles.offerFinalization}
      title="Fertigstellen"
      subtitle="Das Angebot fertigstellen"
    >
      <OfferFinalizationDateTime
        offerStore={offerStore.getBaseOffer()}
        trackingContext="privatkunden.angebot-fertigstellen"
      />
      <OfferFinalizationShopData
        customClass={styles.shopDataContainer}
        offerStore={offerStore.getBaseOffer()}
        distributionData={distributionData}
      />
      <TotalPriceToggle baseOffer={offerStore.baseOffer} />
      <OfferFinalizationLeadKek baseOffer={offerStore.baseOffer} />
    </OfferFinalizationWrapper>
  );
};

export default observer(OfferFinalization);
