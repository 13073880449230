import React, { ReactNode } from "react";
import { CustomerRequirementsBase } from "core/entities/PencilSelling/CustomerRequirementsBase/CustomerRequirementsBase";
import * as styles from "./customer-requirements-mobile.module.scss";
import { InfoModalVariants } from "../CustomerRequirementsInfoModal";
import CustomerRequirementsBandwidth from "../CustomerRequirementsBandwidth";
import CustomerRequirementsDevices from "../CustomerRequirementsDevices";

type CustomerRequirementsMobileProps = {
  components: {
    useCase: ReactNode;
    fiber: ReactNode;
    internationalTariff: ReactNode;
    smartphone: ReactNode;
    magentaShape: ReactNode;
    customerSize: ReactNode;
  };
  customerRequirementsBase: CustomerRequirementsBase;
  isBusinessClient: boolean;
  openInfoModal: (variant: InfoModalVariants) => void;
  trackingContext: string;
};

const CustomerRequirementsMobile = ({
  components,
  customerRequirementsBase,
  isBusinessClient,
  openInfoModal,
  trackingContext,
}: CustomerRequirementsMobileProps) => (
  <div className={styles.mobileOnly}>
    <div className={`${styles.column} ${styles.first}`}>
      <div className={styles.bandwidth}>
        <CustomerRequirementsBandwidth
          isBusinessClient={isBusinessClient}
          openInfoModal={openInfoModal}
          trackingContext={trackingContext}
          customerRequirementsBase={customerRequirementsBase}
        />
        {components.useCase}
      </div>
      {components.internationalTariff}
    </div>
    <div className={`${styles.column} ${styles.second}`}>
      {components.fiber}
      {components.smartphone}
    </div>
    <div className={`${styles.column}`}>
      {components.customerSize}
      <CustomerRequirementsDevices
        trackingContext={trackingContext}
        openInfoModal={openInfoModal}
        customerRequirementsBase={customerRequirementsBase}
        isBusinessClient={isBusinessClient}
      />
    </div>
    <div className={styles.magentaShape}>{components.magentaShape}</div>
  </div>
);

export default CustomerRequirementsMobile;
