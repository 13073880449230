import React, { ReactNode } from "react";
import { Container, Grid } from "@dtpk-cc/components";
import { CustomerRequirementsBase } from "core/entities/PencilSelling/CustomerRequirementsBase/CustomerRequirementsBase";
import * as styles from "./customer-requirements-desktop.module.scss";
import { InfoModalVariants } from "../CustomerRequirementsInfoModal";
import CustomerRequirementsBandwidth from "../CustomerRequirementsBandwidth";
import CustomerRequirementsDevices from "../CustomerRequirementsDevices";

type CustomerRequirementsDesktopProps = {
  components: {
    useCase: ReactNode;
    fiber: ReactNode;
    internationalTariff: ReactNode;
    smartphone: ReactNode;
    magentaShape: ReactNode;
    customerSize: ReactNode;
  };
  customerRequirementsBase: CustomerRequirementsBase;
  isBusinessClient: boolean;
  openInfoModal: (variant: InfoModalVariants) => void;
  trackingContext: string;
};

const CustomerRequirementsDesktop = ({
  components,
  customerRequirementsBase,
  isBusinessClient,
  openInfoModal,
  trackingContext,
}: CustomerRequirementsDesktopProps) => (
  <div className={styles.customerRequirementsContainer}>
    <div className={styles.leftColumn}>
      <div className={styles.bandwidthAndFiber}>
        <div className={styles.bandwidth}>
          <CustomerRequirementsBandwidth
            isBusinessClient={isBusinessClient}
            openInfoModal={openInfoModal}
            trackingContext={trackingContext}
            customerRequirementsBase={customerRequirementsBase}
          />
          {components.useCase}
          <div className={styles.customerSize}>{components.customerSize}</div>
        </div>
        {components.fiber}
      </div>
      <CustomerRequirementsDevices
        trackingContext={trackingContext}
        openInfoModal={openInfoModal}
        customerRequirementsBase={customerRequirementsBase}
        isBusinessClient={isBusinessClient}
      />
    </div>
    <div className={styles.rightColumn}>
      {components.internationalTariff}
      {components.smartphone}
      {components.magentaShape}
    </div>
  </div>
);

export default CustomerRequirementsDesktop;
