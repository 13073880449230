import React from "react";
import { ToggleBtn } from "elements/new-design/ToggleBtn";
import { useStores } from "stores/PrivateClient";
import { HouseholdSize } from "core/entities/PencilSelling/PrivateClient/CustomerRequirements/ICustomerRequirementsPC";
import { observer } from "mobx-react";
import { trackClick } from "helpers/reactTracking";
import { componentLinks } from "components/new-design/CustomerRequirements/const";
import { InfoModalVariants } from "components/new-design/CustomerRequirements/CustomerRequirementsInfoModal";
import CustomerRequirementsRecommendation from "components/new-design/CustomerRequirements/CustomerRequirementsRecommendation";
import CustomerRequirementsSectionHeader from "components/new-design/CustomerRequirements/CustomerRequirementsSectionHeader";
import { getCustomerRequirementsOptionsInterval } from "helpers/CustomerRequirements";
import ChildrenIcon from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/childrenIcon.svg";
import AdultsIcon from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/adultsIcon.svg";
import * as styles from "./customer-requirements-household.module.scss";

const tilesMap = getCustomerRequirementsOptionsInterval(3, [4], null);

type HouseholdItem = {
  icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  identifier: keyof HouseholdSize;
  tiles: string[];
};

const householdContentItems: HouseholdItem[] = [
  {
    icon: AdultsIcon,
    identifier: "adults",
    tiles: tilesMap,
  },
  {
    icon: ChildrenIcon,
    identifier: "children",
    tiles: tilesMap,
  },
];

type CustomerRequirementsHouseholdProps = {
  openInfoModal: (variant: InfoModalVariants) => void;
  trackingContext: string;
};

const CustomerRequirementsHousehold = ({
  openInfoModal,
  trackingContext,
}: CustomerRequirementsHouseholdProps) => {
  const { customerRequirementsStore } = useStores();
  return (
    <div className={styles.householdContainer}>
      <CustomerRequirementsSectionHeader
        sectionTitle="haushalt | community"
        link={componentLinks.household}
        sectionSubtitle="Unbegrenztes Datenvolumen für die Lieblingsmenschen"
        onInfoClick={() => openInfoModal(InfoModalVariants.HOUSEHOLD)}
      />
      <div className={styles.householdItemsContainer}>
        {householdContentItems.map((item) => (
          <div className={styles.householdItemsRow} key={item.identifier}>
            <item.icon className={styles.icon} />
            <div className={styles.householdItems}>
              {item.tiles.map((tile) => (
                <ToggleBtn
                  key={`${item.identifier}_members_${tile}`}
                  checked={
                    customerRequirementsStore.requirements.household[
                      item.identifier
                    ] === tile
                  }
                  onChange={() => {
                    trackClick(
                      `household.${item.identifier}.${tile}`,
                      trackingContext
                    );
                    customerRequirementsStore.setHousehold(
                      item.identifier,
                      tile
                    );
                  }}
                  title={tile}
                  id={`${item.identifier}_members_${tile}`}
                  inputTestId={`${item.identifier}_members_${tile}`}
                  customTileClass={styles.householdItem}
                />
              ))}
            </div>
          </div>
        ))}
        <CustomerRequirementsRecommendation
          description={customerRequirementsStore.recommendations.household}
          customClass={styles.householdRecommendationPosition}
        />
      </div>
    </div>
  );
};

export default observer(CustomerRequirementsHousehold);
