import React, { useState } from "react";
import { CustomerType } from "core/entities/PencilSelling/Customer/ICustomer";
import { trackClick } from "helpers/reactTracking";
import CustomerRequirementsBC from "views/PencilSelling/BusinessClient/CustomerRequirements/index";
import CustomerRequirementsPC from "views/PencilSelling/PrivateClient/CustomerRequirements/index";
import CustomerRequirementsInfoModal, {
  InfoModalVariants,
} from "./CustomerRequirementsInfoModal";

type CustomerRequirementsProps = {
  customerType: CustomerType;
};

const CustomerRequirements = ({ customerType }: CustomerRequirementsProps) => {
  const trackingContext = `umfeldanalyse.${customerType}`;

  const isBusinessClient = customerType === CustomerType.BUSINESS;

  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);
  const [activeModalVariant, setActiveModalVariant] =
    useState<InfoModalVariants>(InfoModalVariants.DEFAULT);

  const openInfoModal = (variant: InfoModalVariants) => {
    trackClick(`modales.${variant}.geöffnet`, trackingContext);
    setInfoModalOpen(true);
    setActiveModalVariant(variant);
  };

  const onCloseInfoModal = () => {
    trackClick(`modales.${activeModalVariant}.schließen`, trackingContext);
    setInfoModalOpen(false);
    setActiveModalVariant(InfoModalVariants.DEFAULT);
  };

  const props = {
    openInfoModal,
    trackingContext,
    customerType,
  };

  return (
    <>
      {isBusinessClient ? (
        <CustomerRequirementsBC {...props} />
      ) : (
        <CustomerRequirementsPC {...props} />
      )}
      <CustomerRequirementsInfoModal
        isOpen={infoModalOpen}
        onClose={onCloseInfoModal}
        variant={activeModalVariant}
      />
    </>
  );
};

export default CustomerRequirements;
