import React from "react";
import { observer } from "mobx-react";
import { Checkbox, Heading } from "@dtpk-cc/components";
import { trackClick } from "helpers/reactTracking";
import TileContainer from "../../TileContainer";
import TileContainerTitle from "../../TileContainer/TileContainerTitle";
import { TileContainerVariants } from "../../TileContainer/ITileContainer";
import * as styles from "./offer-finalization-lead-kek.module.scss";
import { BaseOffer } from "../../../../core/entities/PencilSelling/BaseOffer/BaseOffer";

type OfferFinalizationLeadKekProps = {
  baseOffer: BaseOffer;
};

const trackingContext =
  "wunschtarif-privat.angebot-fertigstellen.kontaktaufnahme";

const OfferFinalizationLeadKek = ({
  baseOffer,
}: OfferFinalizationLeadKekProps) => (
  <TileContainer
    variant={TileContainerVariants.outline}
    customClass={`dataContainer ${styles.wrapper}`}
  >
    <TileContainerTitle
      title="Lead / Kek"
      variants={[Heading.Variant.quaternary, Heading.Variant.uppercase]}
    />
    <div className={styles.labelWrapper}>
      <Heading customClass="m-b-24" variants={[Heading.Variant.quaternary]}>
        Kontaktaufnahme gewünscht
      </Heading>
      <div className={styles.checkBoxWrapper}>
        <Checkbox
          checked={baseOffer.contactKek === true}
          onChange={(contactKek) => {
            baseOffer.setContactKek(contactKek || null);
            trackClick("ja", trackingContext);
          }}
          tabIndex={0}
          role="checkbox"
          id="offer-finalization-lead-kek-yes"
        >
          <span>Ja</span>
        </Checkbox>
        <Checkbox
          checked={baseOffer.contactKek === false}
          onChange={(contactKek) => {
            baseOffer.setContactKek(contactKek ? false : null);
            trackClick("nein", trackingContext);
          }}
          tabIndex={0}
          role="checkbox"
          id="offer-finalization-lead-kek-no"
        >
          <span>Nein</span>
        </Checkbox>
      </div>
    </div>
  </TileContainer>
);

export default observer(OfferFinalizationLeadKek);
