import { FALLBACK_APP_LINK } from "../../../constants/const";
import { DateTimeHelper } from "../../../helpers/DateTimeHelper";
import { ICustomer } from "../../entities/PencilSelling/Customer/ICustomer";

export interface IDetailNote {
  headline?: string;
  text: string;
}

export interface IDistributionPoint {
  name: string;
  pls: string;
  adress: string;
  location: string;
}

export interface IDistributionData {
  distributionLink: string;
  distributionPoint: IDistributionPoint | null;
  showDistributionData?: boolean;
}

export interface IBannerLinks {
  magentaAppLink: string;
  googleReviewLink: string;
  onlineAppointmentBookingLink: string;
}

export interface IOfferSummaryBasePresenterDetails {
  consultingTime: ICustomer["consultingTime"];
  validUntil: Date;
}

export interface IMobileAveragePriceData {
  title: string;
  subtitle: string;
  monthlyPrice: number;
}

export interface IOfferSummaryBasePresenterExport {
  details: IDetailNote[];
  distributionData: IDistributionData;
  bannerLinks: IBannerLinks;
  displayConfigurationTotalPrice: boolean;
  displayOfferTotalPrice: boolean;
  mobileAveragePriceData: IMobileAveragePriceData;
}

export class OfferSummaryBasePresenter {
  defaultDistributionPoint = {
    name: "",
    pls: "",
    adress: "",
    location: "",
  };

  constructor(
    protected bannerLinks: IBannerLinks,
    protected distributionData: IDistributionData,
    protected offerExportDetails: IOfferSummaryBasePresenterDetails,
    protected customerNote: string,
    protected displayConfigurationTotalPrice: boolean,
    protected displayOfferTotalPrice: boolean,
    protected mobileAveragePrice: number | null,
    protected contactKek: boolean | null
  ) {
    this.offerExportDetails = offerExportDetails;
    this.distributionData = {
      showDistributionData: distributionData.showDistributionData,
      distributionPoint:
        distributionData.distributionPoint ?? this.defaultDistributionPoint,
      distributionLink: distributionData.distributionLink,
    };
    this.bannerLinks = {
      magentaAppLink: bannerLinks.magentaAppLink
        ? bannerLinks.magentaAppLink
        : FALLBACK_APP_LINK,
      googleReviewLink: bannerLinks.googleReviewLink || "",
      onlineAppointmentBookingLink:
        bannerLinks.onlineAppointmentBookingLink || "",
    };
    this.displayConfigurationTotalPrice = displayConfigurationTotalPrice;
    this.displayOfferTotalPrice = displayOfferTotalPrice;
    this.mobileAveragePrice = mobileAveragePrice;
    this.contactKek = contactKek;
  }

  protected getCustomerNote(): IDetailNote {
    return {
      headline: "Anmerkungen",
      text: this.customerNote,
    };
  }

  protected static getAgbNote(): IDetailNote {
    return {
      headline: "Hinweis",
      text: "<b>Änderungen und Irrtum vorbehalten.</b> Die Empfehlung ist freibleibend und basiert auf den geltenden Allgemeinen Geschäftsbedingungen von Telekom Deutschland GmbH für das jeweilige Produkt. Die Allgemeinen Geschäftsbedingungen sind in den Telekom Shops, bei den Telekom Partnern oder im Internet unter www.telekom.de/agb erhältlich.",
    };
  }

  protected getValidUntilText(): string | undefined {
    return (
      this.offerExportDetails.validUntil &&
      `<b>Angebot gültig bis:</b> ${DateTimeHelper.currentDisplayDate(
        this.offerExportDetails.validUntil
      )}`
    );
  }

  protected getContactKekText(): string {
    return (
      this.contactKek !== null &&
      `<p><b>Kontaktaufnahme gewünscht:</b> ${
        this.contactKek ? "Ja" : "Nein"
      }</p>`
    );
  }

  protected getConsultingTime(): string | undefined {
    const isDate =
      typeof this.offerExportDetails.consultingTime.nextAppointmentDate
        ?.getMonth === "function";
    const consultingTimeText = `
    ${
      isDate
        ? DateTimeHelper.currentDisplayDate(
            this.offerExportDetails.consultingTime.nextAppointmentDate
          )
        : ""
    } ${
      DateTimeHelper.isTimeValid(
        this.offerExportDetails.consultingTime.nextAppointmentTime
      )
        ? `um ${this.offerExportDetails.consultingTime.nextAppointmentTime} Uhr`
        : ""
    }`.trim();

    return consultingTimeText
      ? `<b>Nächster Beratungstermin:</b> ${consultingTimeText}`
      : undefined;
  }

  protected get offerDates() {
    return {
      validUntil: this.getValidUntilText(),
      consultingTime: this.getConsultingTime(),
    };
  }

  protected getOfferFinalizationDetailText() {
    const offerDates = Object.values(this.offerDates).reduce(
      (acc, item) => (item ? `${acc}<p>${item}</p>` : acc),
      ""
    );
    const contactKekText = this.getContactKekText() || "";

    return offerDates.concat(contactKekText);
  }

  protected getDetails(): IDetailNote[] {
    const notes = [] as IDetailNote[];
    const offerFinalizationDetailText = this.getOfferFinalizationDetailText();
    const customerNote = this.getCustomerNote();
    const getAgbNote = OfferSummaryBasePresenter.getAgbNote();

    if (offerFinalizationDetailText)
      notes.push({ text: offerFinalizationDetailText });
    if (customerNote.text) notes.push(customerNote);
    if (getAgbNote) notes.push(getAgbNote);

    return notes;
  }

  getMobileAveragePriceData(): IMobileAveragePriceData {
    return this.mobileAveragePrice
      ? {
          title: "Durchschnittspreis pro Karte über 24 Monate:",
          subtitle:
            "Berücksichtigt die mtl. Kosten aller Mobilfunk- und Zusatzkarten-Konfigurationen.",
          monthlyPrice: this.mobileAveragePrice,
        }
      : null;
  }

  export(): IOfferSummaryBasePresenterExport {
    return {
      distributionData: this.distributionData,
      bannerLinks: this.bannerLinks,
      details: this.getDetails(),
      displayConfigurationTotalPrice: this.displayConfigurationTotalPrice,
      displayOfferTotalPrice: this.displayOfferTotalPrice,
      mobileAveragePriceData: this.getMobileAveragePriceData(),
    };
  }
}
