// extracted by mini-css-extract-plugin
var _1 = "customer-requirements-streaming-module-bgAppleTv-WvYUK";
var _2 = "customer-requirements-streaming-module-bgDisneyPlus-EenES";
var _3 = "customer-requirements-streaming-module-bgTransparent-HR0e7";
var _4 = "customer-requirements-streaming-module-bgWhite-dKvvY";
var _5 = "customer-requirements-streaming-module-checkBoxNoneWhiteBg-ePryW";
var _6 = "customer-requirements-streaming-module-checkBoxWhite-QDH3q";
var _7 = "customer-requirements-streaming-module-colorMagenta-MIWmC";
var _8 = "customer-requirements-streaming-module-colorWhite-afMNm";
var _9 = "customer-requirements-streaming-module-copyTextStreaming-DMn6c";
var _a = "customer-requirements-streaming-module-icon-Ex7uw";
var _b = "customer-requirements-streaming-module-iconWrapper-SBXBd";
var _c = "customer-requirements-streaming-module-magentaMusicIcon-sLMPW";
var _d = "customer-requirements-streaming-module-magentaTvCheckboxesContainer-QiygS";
var _e = "customer-requirements-streaming-module-streamingCheckbox-rauJK";
var _f = "customer-requirements-streaming-module-streamingContainer-wUAaG";
var _10 = "customer-requirements-streaming-module-streamingDeviceItem-uIG6c";
var _11 = "customer-requirements-streaming-module-streamingDevices-uVqrD";
var _12 = "customer-requirements-streaming-module-streamingDevicesItems-b5GtP";
var _13 = "customer-requirements-streaming-module-streamingRecommendation-j8rGa";
var _14 = "customer-requirements-streaming-module-streamingResolutionWrapper-HWfoj";
var _15 = "customer-requirements-streaming-module-streamingServiceContainer-m1uHA";
var _16 = "customer-requirements-streaming-module-streamingServiceContent-_M6CG";
var _17 = "customer-requirements-streaming-module-streamingServiceContentLeft-LHi_t";
var _18 = "customer-requirements-streaming-module-streamingServiceHeader-j6e_i";
var _19 = "customer-requirements-streaming-module-streamingServiceLogo-hP7su";
var _1a = "customer-requirements-streaming-module-streamingServiceTileFlex-Qo_PO";
var _1b = "customer-requirements-streaming-module-streamingServiceTiles-E9NtM";
var _1c = "customer-requirements-streaming-module-streamingServiceTilesContainer-uzoxx";
var _1d = "customer-requirements-streaming-module-streamingServiceTilesSecondary-NjNu_";
var _1e = "customer-requirements-streaming-module-streamingSubheader-G_ZO5";
export { _1 as "bgAppleTv", _2 as "bgDisneyPlus", _3 as "bgTransparent", _4 as "bgWhite", _5 as "checkBoxNoneWhiteBg", _6 as "checkBoxWhite", _7 as "colorMagenta", _8 as "colorWhite", _9 as "copyTextStreaming", _a as "icon", _b as "iconWrapper", _c as "magentaMusicIcon", _d as "magentaTvCheckboxesContainer", _e as "streamingCheckbox", _f as "streamingContainer", _10 as "streamingDeviceItem", _11 as "streamingDevices", _12 as "streamingDevicesItems", _13 as "streamingRecommendation", _14 as "streamingResolutionWrapper", _15 as "streamingServiceContainer", _16 as "streamingServiceContent", _17 as "streamingServiceContentLeft", _18 as "streamingServiceHeader", _19 as "streamingServiceLogo", _1a as "streamingServiceTileFlex", _1b as "streamingServiceTiles", _1c as "streamingServiceTilesContainer", _1d as "streamingServiceTilesSecondary", _1e as "streamingSubheader" }
