import React from "react";
import { ToggleBtn } from "elements/new-design/ToggleBtn";
import { observer } from "mobx-react";
import { useStores } from "stores/PrivateClient";
import { trackClick } from "helpers/reactTracking";
import { componentLinks } from "components/new-design/CustomerRequirements/const";
import { InfoModalVariants } from "components/new-design/CustomerRequirements/CustomerRequirementsInfoModal";
import CustomerRequirementsRecommendation from "components/new-design/CustomerRequirements/CustomerRequirementsRecommendation";
import CustomerRequirementsSectionHeader from "components/new-design/CustomerRequirements/CustomerRequirementsSectionHeader";
import * as styles from "./customer-requirements-smartphone.module.scss";

const smartphoneConsultationContentMap = {
  items: [
    {
      title: "Neues gewünscht",
      value: true,
    },
    {
      title: "Vorhanden",
      value: false,
    },
  ],
};

type CustomerRequirementsSmartphoneProps = {
  openInfoModal: (variant: InfoModalVariants) => void;
  trackingContext: string;
};

const CustomerRequirementsSmartphone = ({
  openInfoModal,
  trackingContext,
}: CustomerRequirementsSmartphoneProps) => {
  const { customerRequirementsStore } = useStores();

  return (
    <div className={`${styles.smartphoneConsultationContainer}`}>
      <CustomerRequirementsSectionHeader
        link={componentLinks.smartphoneConsultation}
        sectionTitle="Smartphone"
        onInfoClick={() => openInfoModal(InfoModalVariants.SMARTPHONE_DESIRED)}
        customClass={styles.linkTextWidth}
      />
      <div className={styles.smartphoneConsultationItemsContainer}>
        {smartphoneConsultationContentMap.items.map((item) => (
          <ToggleBtn
            id={`smartphone_consultation_${item.value}`}
            inputTestId={`smartphone_consultation_${item.value}`}
            checked={
              customerRequirementsStore.requirements.newSmartphoneDesired ===
              item.value
            }
            onChange={() => {
              trackClick(`smartphoneDesired.${item.value}`, trackingContext);
              customerRequirementsStore.setSmartphoneConsultation(item.value);
            }}
            title={item.title}
            key={`smartphone_consultation_${item.value}`}
            customTileClass={styles.smartphoneConsultationTile}
            customTitleClass={styles.smartphoneConsultationTitle}
            placeItems="start"
          />
        ))}
        <CustomerRequirementsRecommendation
          customClass={styles.recommendation}
          description={
            customerRequirementsStore.recommendations.newSmartphoneDesired
          }
        />
      </div>
    </div>
  );
};
export default observer(CustomerRequirementsSmartphone);
