import React from "react";
import { TextLink } from "@dtpk-cc/components";
import { LpgContinueOutline } from "@dtpk-cc/components/dist/icons";
import { ToggleBtn } from "elements/new-design/ToggleBtn";
import { observer } from "mobx-react";
import { trackClick } from "helpers/reactTracking";
import * as styles from "./customer-requirements-fiber.module.scss";
import { componentLinks } from "../const";
import FiberIcon from "../../../../../assets/images/icons/pencil-selling-tool/tariff-selection/landline-filter.svg";

const fiberCableContentMap = {
  items: [
    {
      identifier: "available",
      title: "Verfügbar",
      value: true,
    },
    {
      identifier: "not_available",
      title: "Ausbaugebiet",
      value: false,
    },
  ],
};

type CustomerRequirementsFiberProps = {
  trackingContext: string;
  fiber: boolean;
  toggleFiber: (value: boolean) => void;
  isBusinessClient: boolean;
};

const CustomerRequirementsFiber = ({
  trackingContext,
  fiber,
  toggleFiber,
  isBusinessClient,
}: CustomerRequirementsFiberProps) => {
  const fiberCheckLink = isBusinessClient
    ? componentLinks.fiberCheck.business
    : componentLinks.fiberCheck.private;

  return (
    <div className={styles.fiberCableContainer}>
      <div className={styles.fiberCableSectionHeader}>
        <FiberIcon className={styles.fiberIcon} />
        <TextLink
          href={fiberCheckLink.href}
          target="_blank"
          iconRight={LpgContinueOutline}
          customClass={styles.fiberCheckLink}
        >
          {fiberCheckLink.label}
        </TextLink>
      </div>
      <div className={styles.fiberCableItemsContainer}>
        {fiberCableContentMap.items.map((item) => (
          <ToggleBtn
            key={`fiber_${item.identifier}`}
            id={`fiber_${item.identifier}`}
            title={item.title}
            onChange={() => {
              trackClick(`fiber.${item.identifier}`, trackingContext);
              toggleFiber(item.value);
            }}
            checked={fiber === item.value}
            inputTestId={`fiber_${item.identifier}`}
            customTileClass={styles.fiberTile}
            customTitleClass={styles.fiberTitle}
            placeItems="start"
          />
        ))}
      </div>
    </div>
  );
};

export default observer(CustomerRequirementsFiber);
